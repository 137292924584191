import { template as template_8d88befac64e48cfa4df2b076600f0a9 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_8d88befac64e48cfa4df2b076600f0a9(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
