import { template as template_5f5a519c2494492a8f2668aabe0a24ac } from "@ember/template-compiler";
const FKText = template_5f5a519c2494492a8f2668aabe0a24ac(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
