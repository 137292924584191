import { template as template_9bbe398cc1f64562aa41ca38b7a9d9dd } from "@ember/template-compiler";
const FKLabel = template_9bbe398cc1f64562aa41ca38b7a9d9dd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
