import { template as template_a3aae68e25e44b16828e2f3b9ba712fc } from "@ember/template-compiler";
const FKControlMenuContainer = template_a3aae68e25e44b16828e2f3b9ba712fc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
